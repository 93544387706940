<template>
    <el-dialog
        :visible.sync="showDialog"
        :close-on-click-modal="true"
        :modal-append-to-body="true"
        :close-on-press-escape="true"
        custom-class="rounded-lg"
        @close="closeDialog"
    >
        <div class="text-center">
            <h3 class="mb-2 text-xl text-black">變更繳回狀態</h3>
            <p class="mb-2 text-gray-300">
                目前狀態:
                <span class="text-red-500">{{
                    paymentStatus[data.status]
                }}</span>
            </p>
        </div>
        <div class="p-5 text-black border rounded-lg">
            <div class="flex mb-2 font-bold">
                <h5 class="flex-1">應繳回金額</h5>
                <p class="OpenSansFont">
                    ${{ data.receivable | formatCurrency }}
                </p>
            </div>
            <div
                v-if="
                    !$isEmpty(data.latest_payment) &&
                    data.latest_payment.type === 'TRANSFER'
                "
                class="flex font-bold"
            >
                <h5 class="flex-1">帳號末四碼</h5>
                <p class="OpenSansFont">
                    {{ data.latest_payment.details.BankAccount }}
                </p>
            </div>
            <div
                v-if="
                    !$isEmpty(data.latest_payment) &&
                    data.latest_payment.type === 'BARCODE'
                "
                class="flex font-bold"
            >
                <h5 class="flex-1">繳費方式</h5>
                <p class="OpenSansFont">
                    {{ paymentTypes[data.latest_payment.type] }}
                </p>
            </div>
        </div>
        <el-form ref="formRefDom" class="mt-5" :model="form" :rules="rules">
            <el-form-item prop="status">
                <div class="flex items-center text-right">
                    <label class="flex-1 max-w-[100px] mr-5">變更狀態</label>
                    <el-select v-model="form.status" class="flex-1 w-full">
                        <el-option
                            v-for="(option, index) in statusOptions.filter(
                                (item) => item.value !== data.status
                            )"
                            :key="index"
                            :value="option.value"
                            :label="option.label"
                        >
                        </el-option>
                    </el-select>
                </div>
            </el-form-item>
            <el-form-item prop="amount">
                <div class="flex items-center text-right">
                    <label class="flex-1 max-w-[100px] mr-5"
                        >實際匯款金額</label
                    >
                    <el-input
                        v-model.number="form.amount"
                        class="flex-1"
                        type="number"
                    ></el-input>
                </div>
            </el-form-item>
            <el-form-item v-if="form.status === -1" prop="description">
                <div class="flex items-center text-right">
                    <label class="flex-1 max-w-[100px] mr-5">問題詳情</label>
                    <el-input
                        v-model="form.description"
                        type="textarea"
                        class="flex-1"
                    ></el-input>
                </div>
            </el-form-item>
        </el-form>
        <div class="flex justify-center">
            <button
                :disabled="loading || isDisabled"
                class="px-3 py-1 text-sm text-white bg-red-500 rounded-lg myDisabled"
                @click="onSubmit"
            >
                確認變更
            </button>
        </div>
    </el-dialog>
</template>

<script >
import { ref, reactive, computed, onActivated, onMounted, watch, getCurrentInstance, defineExpose, onBeforeUnmount } from "@vue/composition-api";
import { useRoute, useRouter } from "vue2-helpers/vue-router";
import { isEmpty } from "@/service/anyService";
// 導入現金單繳費狀態 對應名詞
import orderConfig from "@/config/orderConfig";
const __sfc_main = {};
__sfc_main.props = {
  // 顯示彈窗
  propsShowDialog: {
    type: Boolean,
    default: false
  },
  // 資料
  data: {
    type: Object
  }
};
__sfc_main.setup = (__props, __ctx) => {
  const router = useRouter();
  const route = useRoute();
  const props = __props;
  const showDialog = ref(false);
  const emit = __ctx.emit;
  // 取得全域 this 方法 ex: this.$api, this.$message
  const {
    proxy
  } = getCurrentInstance();
  const loading = ref(false);
  // 現金單繳款狀態名詞
  const paymentStatus = ref(orderConfig.cashOrderPaymentStatus);
  // 現金單付款方式
  const paymentTypes = ref(orderConfig.cashOrderPaymentTypes);
  // 判斷是否 disabled
  const isDisabled = ref(true);
  // 狀態選擇
  const statusOptions = ref([{
    label: "通過審核",
    value: 9
  }, {
    label: "問題款項",
    value: -1
  }]);
  // 表單dom
  const formRefDom = ref(null);
  // 表單資料
  const form = ref({});
  // 驗證規則
  const rules = ref({
    status: [{
      required: true,
      message: "請選擇變更狀態",
      trigger: "blur"
    }],
    amount: [{
      required: true,
      message: "請輸入實際收款額度",
      trigger: "blur"
    }]
  });
  watch(form.value, async newV => {
    if (isEmpty(form.value.status) || isEmpty(form.value.amount)) {
      isDisabled.value = true;
    }
    if (!isEmpty(form.value.status) && !isEmpty(form.value.amount)) {
      isDisabled.value = false;
    }
  });
  /**
   * 表單發送
   */
  async function onSubmit() {
    if (form.value.status === -1) {
      rules.value.description = [{
        required: true,
        message: "請輸入問題詳情",
        trigger: "blur"
      }];
    }
    try {
      await formRefDom.value.validate();
      await update();
    } catch (err) {
      proxy.$message({
        type: "error",
        message: "資料未填寫正確"
      });
    }
  }
  /**
   * 變更狀態 api
   */
  async function update() {
    loading.value = true;
    try {
      await proxy.$api.UpdatePaymentBySlipOrderStatus(route.params.id, form.value);
      proxy.$message({
        type: "success",
        message: "變更成功"
      });
      closeDialog();
    } catch (err) {
      console.log(err);
      proxy.$message({
        type: "error",
        message: "變更狀態失敗"
      });
    } finally {
      loading.value = false;
    }
  }
  /**
   * 關閉彈窗
   */
  function closeDialog() {
    emit("onCloseDialog", false);
    showDialog.value = false;
    form.value = {};
  }
  watch(() => props.propsShowDialog, newV => {
    showDialog.value = newV;
  });
  return {
    showDialog,
    loading,
    paymentStatus,
    paymentTypes,
    isDisabled,
    statusOptions,
    formRefDom,
    form,
    rules,
    onSubmit,
    closeDialog
  };
};
export default __sfc_main;
</script>
