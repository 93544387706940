<template>
    <div>
        <LoadingComponent :isLoading="loading" />

        <div
            class="flex items-center px-5 py-3 mt-5 bg-white rounded-lg shadow-xl"
        >
            <el-collapse class="flex-1">
                <el-collapse-item>
                    <template slot="title">
                        <div
                            class="flex items-center w-full px-4 cursor-pointer"
                            @click="toggleLogs = !toggleLogs"
                        >
                            <div class="flex items-center flex-1">
                                <h2
                                    :class="
                                        form.status === -1
                                            ? 'text-red-500 font-bold'
                                            : ''
                                    "
                                    class="text-base"
                                >
                                    狀態：{{ paymentStatus[form.status] }}
                                </h2>
                                <i
                                    :class="
                                        toggleLogs
                                            ? 'transform rotate-90 '
                                            : 'transform rotate-0 '
                                    "
                                    class="ml-2 transition duration-200 ease-linear ni ni-bold-right"
                                ></i>
                            </div>
                        </div>
                    </template>
                    <div v-if="logs.length > 0">
                        <div
                            v-for="log in logs.slice().reverse()"
                            :key="log.time"
                            class="pb-2 mb-2"
                        >
                            <div class="flex items-center">
                                <div
                                    class="text-sm text-gray-400"
                                    style="width: 80px"
                                >
                                    {{
                                        paymentStatus[log.status_after]
                                    }}&nbsp;:&nbsp;
                                </div>
                                <span class="text-black">{{
                                    log.time | formatDateTime
                                }}</span>

                                <span class="ml-2 text-black"
                                    >| {{ log.administrator.name }}</span
                                >
                            </div>
                        </div>
                    </div>
                </el-collapse-item>
            </el-collapse>
        </div>
        <div class="py-5 mt-4 bg-white rounded-lg">
            <div class="px-[16%]">
                <h2 class="text-lg font-bold">服務商</h2>
                <div class="flex items-center mt-2 w-[300px]">
                    <div class="w-[100px]">
                        <a
                            :href="`/#/user_list/${
                                isProvider ? 'provider_update' : 'member_update'
                            }/${data.user.id}`"
                            target="verifyUser"
                            class="mx-5 text-red-500 underline"
                        >
                            <Avatar
                                :size="['w-10', 'h-10']"
                                :backgroundImg="
                                    data.user.thumbnails.avatar['360x360']
                                "
                            />
                        </a>
                    </div>
                    <a
                        :href="`/#/user_list/${
                            isProvider ? 'provider_update' : 'member_update'
                        }/${data.user.id}`"
                        target="verifyUser"
                        class="mx-5 text-red-500 underline"
                    >
                        {{ data.user.name }}
                    </a>
                    <div
                        class="flex-grow text-right text-red-500"
                        @click="
                            toMessage(
                                data.user.role === 0 ? 'members' : 'providers',
                                data.user.banana_id
                            )
                        "
                    >
                        <i class="fas fa-comment-alt"></i>
                    </div>
                </div>
                <div class="flex w-[300px] mt-2">
                    <div class="text-gray-400 w-[100px]">手機</div>
                    <div class="mx-5">{{ data.user.phone }}</div>
                    <div class="flex-grow text-right">
                        <span
                            class="cursor-pointer"
                            @click="onClipboard(data.user.phone)"
                            ><i class="far fa-clone"></i
                        ></span>
                    </div>
                </div>
                <div class="flex w-[300px] mt-2">
                    <div class="text-gray-400 w-[100px]">電子郵件</div>
                    <div class="mx-5">{{ data.user.email }}</div>
                </div>
            </div>
            <div class="my-10 border-b-2 border-gray-100 mx-[8%]"></div>
            <div class="px-[16%]">
                <div class="p-5">
                    <h5 class="text-lg font-bold">繳款資訊</h5>
                    <div class="flex w-full mt-5">
                        <div class="flex-1 mr-5 border-r">
                            <div class="flex">
                                <p class="min-w-[100px] text-gray-400">
                                    繳回編號
                                </p>
                                <p>{{ data.slip_id }}</p>
                            </div>
                            <div
                                v-if="
                                    !$isEmpty(data.latest_payment) &&
                                    data.latest_payment.type === 'TRANSFER'
                                "
                                class="flex"
                            >
                                <p class="min-w-[100px] text-gray-400">
                                    帳號末四碼
                                </p>
                                <p v-if="!$isEmpty(data.latest_payment)">
                                    {{
                                        data.latest_payment.details.BankAccount
                                    }}
                                </p>
                            </div>
                            <div
                                v-if="
                                    !$isEmpty(data.latest_payment) &&
                                    ['BARCODE', 'VACC'].includes(
                                        data.latest_payment.type
                                    )
                                "
                            >
                                <div class="flex">
                                    <p class="min-w-[100px] text-gray-400">
                                        繳費方式
                                    </p>
                                    <strong class="text-black">{{
                                        paymentTypes[data.latest_payment.type]
                                    }}</strong>
                                </div>
                                <div class="flex">
                                    <p class="min-w-[100px] text-gray-400">
                                        金流單號
                                    </p>
                                    <strong class="text-black">{{
                                        data.latest_payment.details.NewebPay
                                            .response.Result.MerchantID
                                    }}</strong>
                                </div>
                            </div>
                        </div>
                        <div class="flex-1">
                            <div class="flex">
                                <p class="min-w-[100px] text-gray-400">
                                    繳回時間
                                </p>
                                <p v-if="!$isEmpty(data.latest_payment)">
                                    {{
                                        data.latest_payment.pay_time
                                            | formatDateTime
                                    }}
                                </p>
                            </div>
                            <div class="flex">
                                <p class="min-w-[100px] text-gray-400">
                                    應繳回金額
                                </p>
                                <p
                                    v-if="!$isEmpty(data.latest_payment)"
                                    class="OpenSansFont"
                                >
                                    ${{
                                        data.latest_payment.amount
                                            | formatCurrency
                                    }}
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="my-10 border-b-2 border-gray-100 mx-[8%]"></div>
            <div
                v-if="!$isEmpty(data.payments) && form.status === -1"
                class="px-[16%]"
            >
                <div class="p-5">
                    <h5 class="text-lg font-bold">問題款項</h5>
                    <table class="w-full mt-5 border-b rounded-lg shadow-md">
                        <thead class="w-full px-5 text-left border-b">
                            <tr>
                                <th
                                    v-for="(item, index) in problemTheads"
                                    :key="index"
                                    class="py-5"
                                    :class="index === 0 ? 'pl-5' : ''"
                                >
                                    {{ item }}
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="item in data.payments" :key="item.id">
                                <td class="p-5">
                                    <div class="font-bold">
                                        {{ item.order_id }}
                                    </div>
                                </td>
                                <td>
                                    {{ item.pay_time | formatDateTime }}
                                </td>
                                <td>
                                    {{
                                        item.details.BankAccount
                                            | formatCurrency
                                    }}
                                </td>
                                <td class="OpenSansFont">
                                    ${{ data.receivable | formatCurrency }}
                                </td>
                                <td class="OpenSansFont">
                                    ${{ item.amount | formatCurrency }}
                                </td>
                                <td>{{ item.description }}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>

            <div
                v-if="!$isEmpty(data.payments) && form.status === -1"
                class="my-10 border-b-2 border-gray-100 mx-[8%]"
            ></div>
            <div class="px-[16%]">
                <div class="p-5">
                    <h5 class="text-lg font-bold">訂單資訊</h5>
                    <table class="mt-5 border-b orderTable">
                        <thead class="px-5 text-left bg-gray-200">
                            <tr>
                                <th
                                    v-for="(item, index) in tableTheads"
                                    :key="index"
                                    class="py-5"
                                    :class="index === 0 ? 'pl-5' : ''"
                                >
                                    {{ item }}
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="item in data.datings" :key="item.id">
                                <td class="p-5">
                                    <div class="font-bold">
                                        {{ item.order_id }}
                                    </div>
                                    <div
                                        v-if="!$isEmpty(item.details.logs)"
                                        class="text-sm font-light text-gray-500"
                                    >
                                        收款時間:
                                        {{
                                            item.details.logs.pay_by_cash.time
                                                | formatDateTime
                                        }}
                                    </div>
                                    <div
                                        class="text-sm font-light text-gray-500"
                                    >
                                        截止時間:
                                        {{ item.cut_due_time | formatDateTime }}
                                    </div>
                                </td>
                                <td class="OpenSansFont">
                                    ${{ item.price | formatCurrency }}
                                </td>
                                <td class="OpenSansFont">
                                    ${{
                                        (item.gross_price - item.price)
                                            | formatCurrency
                                    }}
                                </td>
                                <td class="OpenSansFont">
                                    ${{
                                        item.provider_remuneration
                                            | formatCurrency
                                    }}
                                </td>
                                <td class="OpenSansFont">
                                    ${{ item.cut | formatCurrency }}
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div class="flex justify-center">
                    <button
                        v-loading="loading"
                        :disabled="loading"
                        class="px-3 py-2 w-[150px] rounded-lg border border-black mr-2 disabled:bg-gray-300 disabled:text-white disabled:cursor-not-allowed disabled:border-gray-300"
                        @click="
                            router.push({
                                name: 'cash_order',
                                params: { status: route.params.status },
                            })
                        "
                    >
                        返回對帳列表
                    </button>
                    <button
                        v-if="![1, -2].includes(data.status)"
                        v-loading="loading"
                        v-permission="['update']"
                        :disabled="loading"
                        class="px-3 py-2 w-[150px] text-white border border-red-500 bg-red-500 rounded-lg disabled:bg-gray-300 disabled:text-white disabled:cursor-not-allowed disabled:border-gray-300"
                        @click="onSubmit()"
                    >
                        變更繳回狀態
                    </button>
                </div>
            </div>
        </div>
        <ChangeStatusDialog
            :data="data"
            :propsShowDialog="showDialog"
            @onCloseDialog="closeDialog"
        />
    </div>
</template>

<script >
import { ref, reactive, computed, onActivated, onMounted, watch, getCurrentInstance, defineExpose, onBeforeUnmount } from "@vue/composition-api";
import { useRoute, useRouter } from "vue2-helpers/vue-router";
import LoadingComponent from "@/components/Loading.vue";
// 導入現金單繳費狀態 對應名詞
import orderConfig from "@/config/orderConfig";
// 頭像組件
import Avatar from "@/components/Avatar.vue";
// 判斷是否有選擇值
import { checkHaveSelectData, isEmpty } from "@/service/anyService";
// 繳回狀態彈窗
import ChangeStatusDialog from "./ChangeStatus.vue";
const __sfc_main = {};
__sfc_main.setup = (__props, __ctx) => {
  const router = useRouter();
  const route = useRoute();
  // 取得全域 this 方法 ex: this.$api, this.$message
  const {
    proxy
  } = getCurrentInstance();
  // 判斷是否為服務商
  const isProvider = computed(() => {
    return data.value.user.role === 0 ? false : true;
  });
  // 交易紀錄
  const logs = ref([]);
  // 判斷是否顯示紀錄
  const toggleLogs = ref(false);
  // 現金單繳款狀態名詞
  const paymentStatus = ref(orderConfig.cashOrderPaymentStatus);
  // 現金單付款方式
  const paymentTypes = ref(orderConfig.cashOrderPaymentTypes);
  const loading = ref(false);
  // 表單標題
  const tableTheads = ref(["訂單", "合計", "平台手續費", "服務商收益", "應繳回款項"]);
  // 表單標題
  const problemTheads = ref(["繳回編號", "繳回時間", "帳號末四碼", "預期繳回", "實際繳回", "問題詳情"]);
  // 表單 dom
  const formRefDom = ref(null);
  // 表單資料
  const form = ref({});
  // 表單驗證規則
  const allRules = ref({
    status: [{
      required: true,
      message: "請選擇審核狀態",
      trigger: "blur"
    }],
    comment: [{
      required: true,
      message: "請輸入未通過原因",
      trigger: "blur"
    }, {
      max: 250,
      message: "未通過原因最多 255字",
      trigger: "blur"
    }]
  });
  const rules = computed(() => {
    // 判斷選擇 未審核通過時 需判斷是否有輸入審核失敗原因
    if (form.value.status === -1) {
      return allRules.value;
    } else {
      return {
        status: allRules.value.status
      };
    }
  });
  // 資料
  const data = ref({
    user: {
      name: "我是服務商",
      thumbnails: {
        avatar: {}
      },
      email: "abc123@gmail.com",
      phone: "+886955831666"
    }
  });
  // 顯示彈窗
  const showDialog = ref(false);
  /**
   * 關閉彈窗
   */
  async function closeDialog() {
    showDialog.value = false;
    await getData();
  }
  /**
   * @param { type String(字串) } tab 判斷預設選中聊天 tab 是服務商列表還是會員列表
   * @param { type String(字串) } id 會員 banana id
   */
  function toMessage(tab, id) {
    window.open(`/#/firestore/chats/${tab}/${id}`);
  }

  /**
   * 複製活動網址
   * @param { type String(字串) } val 活動網址
   */
  function onClipboard(val) {
    proxy.$copyText(val).then(() => {
      proxy.$message({
        type: "success",
        message: "連結複製成功"
      });
    }, () => {
      prox.$message({
        type: "error",
        message: "連結複製失敗"
      });
    });
  }

  // 驗證表單
  async function onSubmit() {
    showDialog.value = true;
  }
  /**
   * 取得資料
   */
  async function getData() {
    loading.value = true;
    try {
      const {
        data: orders
      } = await proxy.$api.GetPaymentBySlipOrderDetailApi(route.params.id);
      data.value = orders;
      form.value = {
        status: orders.status
      };
      // 判斷有交易紀錄才塞入資料
      if (!isEmpty(orders.details) && orders.details.logs.length > 0) {
        logs.value = orders.details.logs;
      }
    } catch (err) {
      console.log(err);
      proxy.$message({
        type: "error",
        message: "取得資料失敗"
      });
    } finally {
      loading.value = false;
    }
  }
  onActivated(() => {
    getData();
  });
  return {
    router,
    route,
    isProvider,
    logs,
    toggleLogs,
    paymentStatus,
    paymentTypes,
    loading,
    tableTheads,
    problemTheads,
    form,
    data,
    showDialog,
    closeDialog,
    toMessage,
    onClipboard,
    onSubmit
  };
};
__sfc_main.components = Object.assign({
  LoadingComponent,
  Avatar,
  ChangeStatusDialog
}, __sfc_main.components);
export default __sfc_main;
</script>

<style lang="scss" scoped>
.orderTable {
    @apply w-full;
    tbody {
        tr:nth-child(odd) {
            @apply bg-gray-50;
        }
        td {
            // @apply p-3;
        }
    }
}

::v-deep {
    .el-collapse {
        border: none;
    }
    .el-collapse-item__header {
        border: none;
    }
    .el-collapse-item__arrow {
        display: none;
    }
    .el-collapse-item__wrap {
        border: none;
    }
}
</style>
