var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('el-dialog',{attrs:{"visible":_vm.showDialog,"close-on-click-modal":true,"modal-append-to-body":true,"close-on-press-escape":true,"custom-class":"rounded-lg"},on:{"update:visible":function($event){_vm.showDialog=$event},"close":_vm.closeDialog}},[_c('div',{staticClass:"text-center"},[_c('h3',{staticClass:"mb-2 text-xl text-black"},[_vm._v("變更繳回狀態")]),_c('p',{staticClass:"mb-2 text-gray-300"},[_vm._v(" 目前狀態: "),_c('span',{staticClass:"text-red-500"},[_vm._v(_vm._s(_vm.paymentStatus[_vm.data.status]))])])]),_c('div',{staticClass:"p-5 text-black border rounded-lg"},[_c('div',{staticClass:"flex mb-2 font-bold"},[_c('h5',{staticClass:"flex-1"},[_vm._v("應繳回金額")]),_c('p',{staticClass:"OpenSansFont"},[_vm._v(" $"+_vm._s(_vm._f("formatCurrency")(_vm.data.receivable))+" ")])]),(
                !_vm.$isEmpty(_vm.data.latest_payment) &&
                _vm.data.latest_payment.type === 'TRANSFER'
            )?_c('div',{staticClass:"flex font-bold"},[_c('h5',{staticClass:"flex-1"},[_vm._v("帳號末四碼")]),_c('p',{staticClass:"OpenSansFont"},[_vm._v(" "+_vm._s(_vm.data.latest_payment.details.BankAccount)+" ")])]):_vm._e(),(
                !_vm.$isEmpty(_vm.data.latest_payment) &&
                _vm.data.latest_payment.type === 'BARCODE'
            )?_c('div',{staticClass:"flex font-bold"},[_c('h5',{staticClass:"flex-1"},[_vm._v("繳費方式")]),_c('p',{staticClass:"OpenSansFont"},[_vm._v(" "+_vm._s(_vm.paymentTypes[_vm.data.latest_payment.type])+" ")])]):_vm._e()]),_c('el-form',{ref:"formRefDom",staticClass:"mt-5",attrs:{"model":_vm.form,"rules":_vm.rules}},[_c('el-form-item',{attrs:{"prop":"status"}},[_c('div',{staticClass:"flex items-center text-right"},[_c('label',{staticClass:"flex-1 max-w-[100px] mr-5"},[_vm._v("變更狀態")]),_c('el-select',{staticClass:"flex-1 w-full",model:{value:(_vm.form.status),callback:function ($$v) {_vm.$set(_vm.form, "status", $$v)},expression:"form.status"}},_vm._l((_vm.statusOptions.filter(
                            (item) => item.value !== _vm.data.status
                        )),function(option,index){return _c('el-option',{key:index,attrs:{"value":option.value,"label":option.label}})}),1)],1)]),_c('el-form-item',{attrs:{"prop":"amount"}},[_c('div',{staticClass:"flex items-center text-right"},[_c('label',{staticClass:"flex-1 max-w-[100px] mr-5"},[_vm._v("實際匯款金額")]),_c('el-input',{staticClass:"flex-1",attrs:{"type":"number"},model:{value:(_vm.form.amount),callback:function ($$v) {_vm.$set(_vm.form, "amount", _vm._n($$v))},expression:"form.amount"}})],1)]),(_vm.form.status === -1)?_c('el-form-item',{attrs:{"prop":"description"}},[_c('div',{staticClass:"flex items-center text-right"},[_c('label',{staticClass:"flex-1 max-w-[100px] mr-5"},[_vm._v("問題詳情")]),_c('el-input',{staticClass:"flex-1",attrs:{"type":"textarea"},model:{value:(_vm.form.description),callback:function ($$v) {_vm.$set(_vm.form, "description", $$v)},expression:"form.description"}})],1)]):_vm._e()],1),_c('div',{staticClass:"flex justify-center"},[_c('button',{staticClass:"px-3 py-1 text-sm text-white bg-red-500 rounded-lg myDisabled",attrs:{"disabled":_vm.loading || _vm.isDisabled},on:{"click":_vm.onSubmit}},[_vm._v(" 確認變更 ")])])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }